<template>
    <div id="sc-header">
        <div id="sc-left-panel">
            <img src="../../../public/favicon.png" />
            <span @click="onClickNavigate(0)">Tickets</span>
            <span @click="onClickNavigate(1)">Customers</span>
            <span @click="onClickNavigate(2)">Products</span>
        </div>
        <div id="sc-right-panel">
            <span
                ><strong>{{ admin_displayname }}</strong></span
            >
            <b-button @click="onClickLogout">Logout</b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            admin_displayname: "",
        };
    },
    methods: {
        onClickLogout() {
            localStorage.setItem("api_token", "");
            this.$router.push({ path: "/login" });
        },
        onClickNavigate(index) {
            console.log(index);
            switch (index) {
                case 0:
                    this.$router.push({ path: "/tickets-onhold" });
                    return;
                case 1:
                    this.$router.push({ path: "/customers" });
                    return;

                case 2:
                    this.$router.push({ path: "/products" });
                    return;
            }
        },
    },
    mounted() {
        this.admin_displayname = localStorage.getItem("admin_displayname");
    },
};
</script>

<style lang="scss">
#sc-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background-color: #fff;
    box-shadow: 2px 5px 5px #888888;

    #sc-left-panel {
        img {
            padding-left: 20px;
        }

        span {
            font-size: 18px;
            padding-left: 40px;
            cursor: pointer;
            user-select: none;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    #sc-right-panel {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;

        button {
            margin-left: 20px;
        }
    }
}
</style>
